import {useEffect} from 'react'
import {KTSVG} from '../../../_ePulse/helpers'
import '../../../App.css'
import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {MenuComponent} from '../../../_ePulse/assets/ts/components'
import {checkPermission} from '../../App'

export const UserListTable = (props: any) => {
  const navigate = useNavigate()
  console.log(props)
  const initial = (firstName: string, lastName: string) => {
    const name: any = `${firstName} ${lastName}`
    let initialName = name
      .match(/(^\S\S?|\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase()
    return initialName
  }

  useEffect(() => {
    // initial()
  }, [])

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])

  return (
    <>
      <div className='dashboard-table-row '>
        <div className='d-flex flex-column me-2 col-3 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value text-truncate' style={{maxWidth: '230px'}}>
              {props.user.firstName} {props.user.lastName}
            </span>
          </div>
        </div>

        <div className='d-flex flex-column me-2 col-2 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>{props.user.mobileNumber || 'NA'}</span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-3 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className=' col-value text-truncate' style={{maxWidth: '230px'}}>
              {props?.user?.email ? props?.user?.email : 'NA'}
            </span>
          </div>
        </div>
        <div className='d-flex flex-column me-2 col-2 justify-content-center'>
          <div className='d-flex flex-stack'>
            <span className='col-value'>{moment(props?.user?.createdAt).format('YYYY-MM-DD')}</span>
          </div>
        </div>

        <div className='d-flex flex-row me-2 col-2 justify-content-start '>
          {checkPermission('getMyUserProfile') && (
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => navigate('/users/view', {state: {userId: props?.user?.id}})}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                <KTSVG path='/media/ePulse/view_icon.svg' className='m-0' />
              </button>
            </div>
          )}
          {checkPermission('adminEditUserProfile') && (
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <button
                onClick={() => navigate('/users/edit', {state: {userId: props?.user?.id}})}
                className='menu-link px-3 text-dark border-0 bg-white fw-semibold'
              >
                <KTSVG path='/media/ePulse/edit_icon.svg' className='m-0' />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
