import {gql} from '@apollo/client'

export const ADD_USER = gql`
  mutation AddUser(
    $mobileNumber: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $gender: GENDER!
  ) {
    createAdminUser(
      userInput: {
        mobileNumber: $mobileNumber
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        gender: $gender
      }
    ) {
      id
      email
      userRole {
        associatedRole
        isActive
      }
    }
  }
`

export const GET_PATIENT_DETAILS = gql`
  query GetPatientProfile($id: ID!) {
    getPatientProfile(id: $id) {
      id
      firstName
      lastName
      dob
      gender
      extraPhoneNo
      extraEmail
      preferredLanguage
      patientAddress {
        street
        city
        state
        zipCode
        country
      }
      isActive
    }
  }
`

export const GET_USER_DETAILS = gql`
  query getMyUserProfile($userId: ID!) {
    getMyUserProfile(userId: $userId) {
      userProfile {
        id
        firstName
        lastName
        gender
        email
        dob
        userTechDetails {
          mciNumber
          stateCouncil
          specialty
          isDoctorCertified
          doctorCertificates
        }
      }
      countryCode
      mobileNumber
      role
      isRegistered
      userAddress {
        street
        city
        district
        state
        zipCode
        country
      }
    }
  }
`
export const EDIT_USER_MUTATION = gql`
  mutation adminEditUserProfile(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $dob: Date
    $gender: GENDER
    $email: String
    $address: String
    $district: String
    $city: String
    $state: String
    $zipCode: Int
    $country: String
  ) {
    adminEditUserProfile(
      userId: $userId
      userProfileInput: {
        firstName: $firstName
        lastName: $lastName
        dob: $dob
        gender: $gender
        email: $email
        address: $address
        district: $district
        city: $city
        state: $state
        zipCode: $zipCode
        country: $country
      }
    ) {
      userId
    }
  }
`

export const EDIT_USER = gql`
  mutation editPatientProfile(
    $firstName: String!
    $lastName: String
    $dob: DateTime
    $gender: GENDER
    $extraEmail: String
    $extraPhoneNo: String
    $patientId: ID!
    $isActive: Boolean
    $street: String
    $city: String
    $state: String
    $zipCode: String
    $country: String
  ) {
    editPatientProfile(
      patientId: $patientId
      patientEditInput: {
        firstName: $firstName
        lastName: $lastName
        dob: $dob
        gender: $gender
        extraEmail: $extraEmail
        extraPhoneNo: $extraPhoneNo
        isActive: $isActive
        patientAddress: {
          street: $street
          city: $city
          state: $state
          zipCode: $zipCode
          country: $country
        }
      }
    ) {
      id
      firstName
      lastName
    }
  }
`

export const GET_USER_LIST = gql`
  query getPeginatedUserList($pageNumber: Int!, $limit: Int!, $field: String, $value: String) {
    getPeginatedUserList(
      listFilter: {page: $pageNumber, limit: $limit}
      searchQuery: {field: $field, value: $value}
    ) {
      data {
        id
        referredByName
        firstName
        lastName
        mobileNumber
        referredBy
        email
      }
      totalPages
      page
      pageSize
      total
    }
  }
`

export const USER_LIST_QUERY = gql`
  query getDashboardUserList($startDate: DateTime!, $endDate: DateTime!) {
    getDashboardUserList(
      listFilter: {startDate: $startDate, endDate: $endDate, page: 1, limit: 10}
    ) {
      id
      firstName
      lastName
      mobileNumber
      referredBy
      email
      referredByName
      createdAt
    }
  }
`
